import React, {Component, Suspense} from "react";
import Lottie from "react-lottie-player";
import Loading from "../../containers/loading/Loading";

export default class DisplayLottie extends Component {
  render() {
    const animationData = this.props.animationData;
    return (
      <Suspense fallback={<Loading />}>
        <Lottie animationData={animationData} loop play/>
      </Suspense>
    );
  }
}
