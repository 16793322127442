/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 1000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Sergey Lyapustin",
  title: "Hi all, I'm Sergey",
  subTitle: emoji(
    "A passionate Senior Software Engineer 🚀 with years of experience building Web applications with Python / Django / ReactJS / VueJs / jQuery and other technologies and frameworks."
  ),
  resumeLink:
    "", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/slyapustin",
  linkedin: "https://www.linkedin.com/in/slyapustin/",
  gmail: "hi@slyapustin.com",
  gitlab: "https://gitlab.com/lyapustin",
  medium: "https://medium.com/@s.lyapustin",
  stackoverflow: "https://stackoverflow.com/users/656984/sergey-lyapustin",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I Do",
  subTitle: "I BUILD EFFECTIVE AND MAINTAINABLE SOLUTIONS",
  skills: [
    emoji(
      "⚡ Develop highly effective web applications."
    ),
    emoji("⚡ Build reliable and manageable CI/CD solution."),
    emoji(
      "⚡ Integration of third party services and data providers."
    ),
    emoji(
      "⚡ Serverless-first approach in development."
    ),
    emoji(
      "⚡ Simple is better than complex."
    ),
    emoji(
      "⚡ Complex is better than complicated."
    ),
    emoji("⚡ Beautiful is better than ugly."
    ),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "HTML5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "Vue.js",
      fontAwesomeClassname: "fab fa-vuejs"
    },
    {
      skillName: "Sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "React",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "Node.js",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "Database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "AWS",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "Git",
      fontAwesomeClassname: "fab fa-git"
    },
    {
      skillName: "Jenkins",
      fontAwesomeClassname: "fab fa-jenkins"
    },
    {
      skillName: "Linux",
      fontAwesomeClassname: "fab fa-linux"
    },
    {
      skillName: "Mailchimp",
      fontAwesomeClassname: "fab fa-mailchimp"
    },
    {
      skillName: "Azure",
      fontAwesomeClassname: "fab fa-microsoft"
    },
    {
      skillName: "Stripe",
      fontAwesomeClassname: "fab fa-stripe"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: false, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Harvard University",
      logo: require("./assets/images/harvardLogo.png"),
      subHeader: "Master of Science in Computer Science",
      duration: "September 2017 - April 2019",
      desc: "Participated in the research of XXX and published 3 papers.",
      descBullets: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      ]
    },
    {
      schoolName: "Stanford University",
      logo: require("./assets/images/stanfordLogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2013 - April 2017",
      desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...",
      descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Backend",
      progressPercentage: "100%"
    },
    {
      Stack: "Programming",
      progressPercentage: "100%"
    },
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: false, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Expert Python Engineer",
      company: "Savings Champion",
      companylogo: require("./assets/images/savingChampion.png"),
      date: "2019",
      desc: "Sergey came on board when we were dealing with several complex issues after a developer had departed unexpectedly. He quickly came up to speed on this rather complex project and begin to help us solve problems and fix issues right away. He is very professional, uses good coding practices and is a good communicator. He is a great team mate and I would not hesitate to hire him again for a Python/Django project."
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/tmf-cap.webp"),
      projectName: "The Motley Fool",
      projectDesc: "Making The World Smarter, Happier, And Richer.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.fool.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/strolid.webp"),
      projectName: "Strolid",
      projectDesc: "Never Miss a Lead ​Never Miss a Customer ​Never Miss a Call",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://strolid.com/"
        }
      ]
    },
    {
      image: require("./assets/images/snapeda.jpeg"),
      projectName: "SnapEDA",
      projectDesc: "Design electronics in a snap.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://snapeda.com/"
        }
      ]
    },
    {
      image: require("./assets/images/microburbs.jpeg"),
      projectName: "Microburbs",
      projectDesc: "Get the facts the property ad left out. Free reports with commute times, cafes, schools, neighborhood demographics, noise, income, and more!",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.microburbs.com.au/"
        }
      ]
    },
    {
      image: require("./assets/images/human.png"),
      projectName: "Human",
      projectDesc: "Human humanises technology to decipher subliminal facial expressions into emotion and characteristics, predicting human behaviour.",
      footerLink: [
        {
          name: "Read More on Crunchbase",
          url: "https://www.crunchbase.com/organization/city-sail"
        }
      ]
    },
    {
      image: require("./assets/images/foreside.jpeg"),
      projectName: "Foreside Connect",
      projectDesc: "ForesideConnect® is a proprietary workflow management system for the compliance consulting clients.",
      footerLink: [
        {
          name: "Read More",
          url: "https://www.foreside.com/technology-solutions/foresideconnect/"
        }
      ]
    },
    {
      image: require("./assets/images/homes.jpeg"),
      projectName: "Homes by Dream",
      projectDesc: "Homes by Dream is a company dedicated to building vibrant communities and the beautiful homes in them.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.homesbydream.ca/"
        }
      ]
    },
    {
      image: require("./assets/images/adventr.jpeg"),
      projectName: "Adventr",
      projectDesc: "Adventr is the world's most powerful and flexible interactive streaming platform.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://adventr.io/"
        }
      ]
    },    
    {
      image: require("./assets/images/2s.png"),
      projectName: "Two Sigma",
      projectDesc: "Two Sigma's scientists bring rigorous inquiry, data analysis, and invention to help solve the toughest challenges across financial services.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.twosigma.com/"
        }
      ]
    },     
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done!",

  achievementsCards: [
    {
      title: "Upwork Top Rated Plus",
      subtitle:
        "Sergey demonstrated proven success on large and long-term contracts. He built a strong reputation on Upwork by getting positive feedback, including work on high-value contracts. He represents the top 3% of performers on Upwork.",
      image: require("./assets/images/up.png"),
      imageAlt: "Upwork Logo",
      footerLink: [
        {
          name: "Upwork Profile",
          url: "https://www.upwork.com/freelancers/~01f20ad1cd11acd65f"
        }
      ]
    },
    {
      title: "Arctic Code Vault Contributor",
      subtitle:
        "The GitHub Arctic Code Vault is a data repository preserved in the Arctic World Archive (AWA), a very-long-term archival facility 250 meters deep in the permafrost of an Arctic mountain.",
      image: require("./assets/images/arctic-code-vault-contributor.png"),
      imageAlt: "Arctic Code Vault Logo",
      footerLink: [
        {
          name: "Learn more about Arctic Code Vault",
          url: "https://archiveprogram.github.com/arctic-vault/"
        }
      ]
    },
    {
      title: "Open-Source Projects Contributor",
      subtitle:
        "Sergey contributed to multiple OSS projects, including Requests, Django, Celery, Apache Airflow, Django Rest Framework, PynamoDB, and others.",
      image: require("./assets/images/oss-contributions.png"),
      imageAlt: "Open Source Projects Logos",
      footerLink: [
        {
          name: "See Contributions on GitHub",
          url: "https://github.com/slyapustin?tab=overview"
        }
      ]
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://medium.com/itnext/how-i-build-my-own-amazon-s3-storage-5de56b4c6612",
      title: "How I Build My Own Amazon S3 Storage",
      description:
        "This is an educational project where we going to implement basic functionality of the AWS S3 Storage."
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    //{
      //title: "Build Actions For Google Assistant",
      //subtitle: "Codelab at GDG DevFest Karachi 2019",
      //slides_url: "https://bit.ly/saadpasta-slides",
      //event_url: "https://www.facebook.com/events/2339906106275053/"
    //}
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me 📬"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  email_address: "hi@slyapustin.com"
};

// Twitter Section

const twitterDetails = {
  userName: "slyapustin", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
